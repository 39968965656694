<script setup lang="ts">
import { HeaderTheme } from '@/types';

/********************
 * COMPOSITIONS     *
 ********************/
const appStore = useAppStore();
const getRouteBaseName = useRouteBaseName();
const route = useRoute();
const viewport = useViewport();
const { fetchFailed, fetchStatus } = useFetchError();

/********************
 * PROPS & EMITS    *
 ********************/
const props = defineProps({
  bgGray: { type: Boolean, default: false },
});

/********************
 * REFS & VARS      *
 ********************/
const routeBaseName = computed(() => {
  return getRouteBaseName(route);
});

const pageClass = computed(() => {
  return [
    'page',
    routeBaseName.value
    && `page--${routeBaseName.value
      ?.split(/(?=[A-Z])/)
      .join('-')
      .toLowerCase()}`,
    (props.bgGray || hasGrayFooter.value) && 'bg-gray-10',
  ];
});

const hasGrayFooter = computed(() => {
  if (props.bgGray || fetchFailed.value || !routeBaseName.value) {
    return true;
  }

  return [
    RBN_CAMPSITE_RATING_WIDGET,
    RBN_CAMPSITE_SEARCH,
    RBN_CITY_SEARCH,
    RBN_COUNTRY_SEARCH,
    RBN_FEDERAL_STATE_SEARCH,
    RBN_REGION_SEARCH,
    RBN_SEARCH,
  ].includes(routeBaseName.value);
});

const showAd = computed(() => {
  return appStore.floorAdActive && !appStore.floorAdClosed;
});

const isHome = computed(() => {
  return routeBaseName.value === 'index';
});

/********************
 * FUNCTIONS         *
 ********************/
function onOverlayClicked() {
  appStore.sidebarOpen = false;
  appStore.userMenuOpen = false;
}

function onSetNavigation(value: boolean) {
  appStore.sidebarOpen = value;
}
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <!-- app mobile menu -->
    <CiSidebar class="block lg:hidden" :open="appStore.sidebarOpen" />

    <!-- header -->
    <slot name="header">
      <CiHeader
        :theme="isHome ? HeaderTheme.gradient : null"
        :loading-theme="isHome ? HeaderTheme.transparent : null"
        :loading-name="isHome ? 'getHomeData' : null"
        @set-navigation="onSetNavigation"
      />
    </slot>

    <!-- main content -->
    <main class="grow" :class="pageClass">
      <template v-if="!fetchFailed">
        <slot />
      </template>
      <CiErrorView v-else :error="fetchStatus" />
    </main>

    <!-- footer -->
    <slot name="footer">
      <LazyCiFooter :bg-gray="hasGrayFooter" />
    </slot>

    <!-- ads -->
    <client-only>
      <NuxtLazyHydrate>
        <div v-if="showAd && viewport.isLessThan('md')" class="ad--mobile-sticky fixed" style="z-index: 40">
          <CiAdSlot id="camping_mobile_stickyad" class="ad--mobile-sticky" />
          <button
            v-if="!appStore.floorAdClosed"
            type="button"
            class="ad__close button button--icon absolute"
            aria-label="Close"
            @click="appStore.floorAdClosed = true"
          />
        </div>
      </NuxtLazyHydrate>
      <NuxtLazyHydrate>
        <div v-if="showAd && viewport.isGreaterOrEquals('md')" class="ad--desktop-sticky fixed" style="z-index: 40">
          <CiAdSlot id="camping_floorad" class="ad--desktop-sticky" />
          <button
            v-if="!appStore.floorAdClosed"
            type="button"
            class="ad__close button button--icon absolute"
            aria-label="Close"
            @click="appStore.floorAdClosed = true"
          />
        </div>
      </NuxtLazyHydrate>
    </client-only>

    <!-- overlay -->
    <CiOverlay v-if="appStore.sidebarOpen" class="block lg:hidden" @click="onOverlayClicked" />
  </div>
</template>
